import React, { useEffect } from "react";
import $ from "jquery";

export default function HCPFooter() {
    useEffect(() => {
        $(".datepicker").datepicker({
            format: "mm/dd/yy",
            showButtonPanel: true,
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            selectOtherMonths: true,
        });
        $(document).ready(function () {
            $(".holidays").click(function (event) {
                event.preventDefault();
                $(".holidays-popup").css("display", "block");
            });

            $(".holidays-popup .close-button").click(function (event) {
                event.preventDefault();
                $(".holidays-popup").css("display", "none");
            });
        });
    });
    return (
        <div>
            <div className="footer">
                <div className="holidays-popup" style={{ color: "#534e4e" }}>
                    <div className="popup-box" style={{ padding: "35px" }}>
                        <p>
                            We are open from <span className="noWrap">9 a.m.–8 p.m. ET</span>, Monday through Friday, except for the
                            following holidays:
                        </p>
                        <ul>
                            <li>New Year's Day</li>
                            <li>Martin Luther King Jr. Day</li>
                            <li>Memorial Day</li>
                            <li>Independence Day</li>
                            <li>Labor Day</li>
                            <li>Thanksgiving Holiday (Thursday and Friday)</li>
                            <li>Christmas Holiday</li>
                        </ul>
                        <p>
                            <button className="close-button">Close</button>
                        </p>
                    </div>
                </div>
                <div className="container">
                    <p>
                        <strong>Questions about this program?</strong>
                    </p>
                    <div className="col-xs-12 col-sm-12 col-md-7 float-left">
                        <p>
                            <img src="../images/phone.png" style={{ float: "left", marginRight: "10px" }} />
                            Call{" "}
                            <span className="noWrap">
                                <strong>(855) 965-2472</strong>
                            </span>
                            <br />9 a.m.–8 p.m. ET, Monday through Friday (except{" "}
                            <a href="#" style={{ color: "#fff" }} className="holidays">
                                major holidays
                            </a>
                            )
                            <br />
                            &nbsp;
                            <br />
                            For questions about XOLAIR, please call <strong>(866) 4XOLAIR</strong> (866-496-5247).
                        </p>
                        <p>
                            <span style={{ float: "left" }}>
                                This website is a service of Genentech, Inc. and Novartis Pharmaceuticals Corporation and is intended for US
                                residents only.
                                <br />
                                <span style={{ fontSize: "11px" }}>&nbsp;</span>
                                <br />©{curr_year} Genentech USA, Inc. and Novartis Pharmaceuticals Corporation.
                            </span>
                            <br />
                            All rights reserved.
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5 float-right">
                        <p className="fax">
                            <img src="../images/fax.png" style={{ float: "left", marginRight: "10px" }} />
                            <span style={{ float: "left" }}>
                                Fax <strong>(866) 440-0599</strong>
                            </span>
                        </p>
                        <p>
                            {" "}
                            <img style={{ marginBottom: "30px", marginTop: "30px" }} src="../images/genentech_novartis.png" />
                        </p>
                        <p className="privacy">
                            <a href="https://www.gene.com/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                            &nbsp;|&nbsp;<a className="ot-sdk-show-settings">Cookie Preferences</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="lightbox-holder">
                <div className="text-enlarger" id="text-enlarger">
                    <div className="title">
                        <div className="title-holder">
                            <h2 className="text-title" style={{ fontSize: "1.25em", lineHeight: "1.49em" }}>
                                Welcome
                            </h2>
                        </div>
                    </div>
                    <div className="text-enlarger-body" id="text-enlarger-body">
                        <div className="content-holder">
                            <strong className="txt-area">How would you like to read? Click one below.</strong>
                            <div className="enlarger-bar">
                                <a href="#" className="size-link" rel="14" id="enlarger-size-small">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <div className="enlarger-bar">
                                <a className="size-link" href="#" rel="18" id="enlarger-size-medium">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <div className="enlarger-bar">
                                <a className="size-link" href="#" rel="22" id="enlarger-size-large">
                                    I like to read words at this size.
                                </a>
                            </div>
                            <a id="fancybox-close" className="fancybox-close">
                                Close
                            </a>
                            <p>
                                At any time, click this <img className="ico-size" src="../images/ico-size.gif" alt="image description" /> to
                                change word size.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
