import { gql } from "@apollo/client";

export const UPDATE_PATIENT_PROFILE = gql`
    mutation Patientupdate(
        $patient_id: Int!
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zip_code: String
        $dob: String
        $phone: String
        $phone_type: String
        $email: String
    ) {
        patientupdate(
            input: {
                patient_id: $patient_id
                enrollment_data: {
                    address: { state: $state, address_1: $address1, city: $city, zip_code: $zip_code, address_2: $address2 }
                    dob: $dob
                    phone: $phone
                    phone_type: $phone_type
                    email: $email
                }
            }
        ) {
            patient_id
        }
    }
`;

export const UPDATE_PATIENT_INSURANCE = gql`
    mutation Patientupdatemedins(
        $patient_id: Int!
        $name: String
        $plan_type: String
        $member_number: String
        $group_number: String
        $effective_date: String
        $bin: String
        $pcn: String
    ) {
        patientupdatemedins(
            input: {
                patient_id: $patient_id
                enrollment_data: {
                    commercial_insurance: {
                        medical_insurance: {
                            name: $name
                            plan_type: $plan_type
                            member_number: $member_number
                            group_number: $group_number
                            effective_date: $effective_date
                            bin: $bin
                            pcn: $pcn
                        }
                    }
                }
            }
        ) {
            patient_id
        }
    }
`;

export const UPDATE_PATIENT_PHARMACY = gql`
    mutation Patientupdaterxins(
        $patient_id: Int!
        $name: String
        $member_number: String
        $group_number: String
        $bin: String
        $pcn: String
    ) {
        patientupdaterxins(
            input: {
                patient_id: $patient_id
                enrollment_data: {
                    commercial_insurance: {
                        rx_insurance: { name: $name, member_number: $member_number, group_number: $group_number, bin: $bin, pcn: $pcn }
                    }
                }
            }
        ) {
            patient_id
        }
    }
`;

export const CREATE_ACCOUNT = gql`
    query Createpatientuser($login_id: String!, $password_hash: String!, $xref_id: Int!) {
        createpatientuser(login_id: $login_id, password_hash: $password_hash, user_type: "patient", xref_id: $xref_id) {
            message
        }
    }
`;

export const PATIENT_ENROLLMENT = gql`
    mutation Patientenrollment(
        $patient_data: String!
        $provider_data: String!
        $patient_provider_data: String!
        $current_enrollment_date_drug: String!
        $current_enrollment_date_drug_admin: String!
        $original_enrollment_date_drug: String!
        $original_enrollment_date_drug_admin: String!
        $enrollment_source: String!
        $drug_patient: String!
        $drug_admin_patient: String!
        $request_card: String!
        $original_enrollment_date: String!
        $enrollment_date: String!
        $first_name: String!
        $last_name: String!
        $date_of_birth: String!
        $gender: String!
        $phone: String!
        $phone_type: String!
        $email: String!
        $address1: String!
        $address2: String!
        $city: String
        $state: String!
        $zip_code: String!
        $enrolled_by: String!
        $email_opt_in_response: String!
        $lap_first_name: String!
        $lap_last_name: String!
        $lap_phone: String!
        $lap_address1: String!
        $lap_address2: String!
        $lap_city: String!
        $lap_state: String!
        $lap_zip_code: String!
        $mi_name: String!
        $mi_plan_type: String!
        $mi_member_number: String!
        $mi_effective_date: String!
        $mi_group_number: String!
        $mi_bin: String!
        $mi_pcn: String!
        $rx_name: String!
        $rx_plan_type: String!
        $rx_member_number: String!
        $rx_group_number: String!
        $rx_bin: String!
        $rx_pcn: String!
        $drug_answer_01: String!
        $drug_answer_02: String!
        $drug_answer_03: String!
        $drug_answer_04: String!
        $drug_answer_05: String!
        $drug_answer_06: String!
        $drug_answer_07: String!
        $drug_answer_08: String!
        $drug_answer_09: String!
        $drug_answer_10: String!
        $admin_answer_01: String!
        $admin_answer_02: String!
        $admin_answer_03: String!
        $admin_answer_04: String!
        $admin_answer_05: String!
        $admin_answer_06: String!
        $admin_answer_07: String!
        $admin_answer_08: String!
        $admin_answer_09: String!
        $admin_answer_10: String!
    ) {
        patientenrollment(
            input: {
                member_id: "NULL"
                enrollment_status: "Pending"
                current_enrollment_date_drug: $current_enrollment_date_drug
                current_enrollment_date_drug_admin: $current_enrollment_date_drug_admin
                original_enrollment_date_drug: $original_enrollment_date_drug
                original_enrollment_date_drug_admin: $original_enrollment_date_drug_admin
                enrollment_source: $enrollment_source
                drug_patient: $drug_patient
                drug_admin_patient: $drug_admin_patient
                credentials_id: "NULL"
                benefits_amounts: {
                    prior_period: {
                        total_amount_drug: 15000
                        total_amount_drug_admin: 1500
                        balance_amount_drug: 15000
                        balance_amount_drug_admin: 1500
                        used_amount_drug: 0
                        used_amount_drug_admin: 0
                        use_count_drug: 0
                        use_count_drug_admin: 0
                    }
                    current_period: {
                        total_amount_drug: 15000
                        total_amount_drug_admin: 1500
                        balance_amount_drug: 15000
                        balance_amount_drug_admin: 1500
                        used_amount_drug: 0
                        used_amount_drug_admin: 0
                        use_count_drug: 0
                        use_count_drug_admin: 0
                    }
                }
                patient_data: $patient_data
                enrollment_data: {
                    request_card: $request_card
                    original_enrollment_date: $original_enrollment_date
                    enrollment_date: $enrollment_date
                    first_name: $first_name
                    last_name: $last_name
                    date_of_birth: $date_of_birth
                    gender: $gender
                    phone: $phone
                    phone_type: $phone_type
                    email: $email
                    address: { address_1: $address1, address_2: $address2, city: $city, state: $state, zip_code: $zip_code }
                    enrolled_by: $enrolled_by
                    email_opt_in: $email_opt_in_response
                    lap: {
                        first_name: $lap_first_name
                        last_name: $lap_last_name
                        phone: $lap_phone
                        address: {
                            address_1: $lap_address1
                            address_2: $lap_address2
                            city: $lap_city
                            state: $lap_state
                            zip_code: $lap_zip_code
                        }
                    }
                    commercial_insurance: {
                        medical_insurance: {
                            name: $mi_name
                            plan_type: $mi_plan_type
                            member_number: $mi_member_number
                            effective_date: $mi_effective_date
                            group_number: $mi_group_number
                            bin: $mi_bin
                            pcn: $mi_pcn
                        }
                        rx_insurance: {
                            name: $rx_name
                            plan_type: $rx_plan_type
                            member_number: $rx_member_number
                            group_number: $rx_group_number
                            bin: $rx_bin
                            pcn: $rx_pcn
                        }
                    }
                }
                patient_provider_data: $patient_provider_data
                provider_data: $provider_data
                current_year_used: "0"
                eligibility_data: {
                    drug: {
                        answer_01: $drug_answer_01
                        answer_02: $drug_answer_02
                        answer_03: $drug_answer_03
                        answer_04: $drug_answer_04
                        answer_05: $drug_answer_05
                        answer_06: $drug_answer_06
                        answer_07: $drug_answer_07
                        answer_08: $drug_answer_08
                        answer_09: $drug_answer_09
                        answer_18: $drug_answer_10
                    }
                    admin: {
                        answer_01: $admin_answer_01
                        answer_02: $admin_answer_02
                        answer_03: $admin_answer_03
                        answer_04: $admin_answer_04
                        answer_05: $admin_answer_05
                        answer_06: $admin_answer_06
                        answer_07: $admin_answer_07
                        answer_08: $admin_answer_08
                        answer_09: $admin_answer_09
                        answer_18: $admin_answer_10
                    }
                }
            }
        ) {
            patient_id
            member_id
            drug_patient
            drug_admin_patient
            enrollment_status
            benefits_amounts {
                current_period {
                    total_amount_drug
                    total_amount_drug_admin
                    balance_amount_drug
                    balance_amount_drug_admin
                    used_amount_drug
                    used_amount_drug_admin
                    use_count_drug
                    use_count_drug_admin
                }
            }
            enrollment_data {
                first_name
                last_name
                date_of_birth
                gender
                phone
            }
            verification_data
            credentials_id
            patient_data
        }
    }
`;

export const UPDATE_PATIENT_ENROLLMENT = gql`
    mutation Patientenrollmentupdate(
        $patient_id: String!
        $program: String!
        $enrollment_date: String!
        $drug_answer_01: String!
        $drug_answer_02: String!
        $drug_answer_03: String!
        $drug_answer_04: String!
        $drug_answer_05: String!
        $drug_answer_06: String!
        $drug_answer_07: String!
        $drug_answer_08: String!
        $drug_answer_09: String!
        $drug_answer_10: String!
        $admin_answer_01: String!
        $admin_answer_02: String!
        $admin_answer_03: String!
        $admin_answer_04: String!
        $admin_answer_05: String!
        $admin_answer_06: String!
        $admin_answer_07: String!
        $admin_answer_08: String!
        $admin_answer_09: String!
        $admin_answer_10: String!
    ) {
        patientenrollmentupdate(
            input: {
                patient_id: $patient_id
                program: $program
                enrollment_date: $enrollment_date
                eligibility_data: {
                    drug: {
                        answer_01: $drug_answer_01
                        answer_02: $drug_answer_02
                        answer_03: $drug_answer_03
                        answer_04: $drug_answer_04
                        answer_05: $drug_answer_05
                        answer_06: $drug_answer_06
                        answer_07: $drug_answer_07
                        answer_08: $drug_answer_08
                        answer_09: $drug_answer_09
                        answer_18: $drug_answer_10
                    }
                    admin: {
                        answer_01: $admin_answer_01
                        answer_02: $admin_answer_02
                        answer_03: $admin_answer_03
                        answer_04: $admin_answer_04
                        answer_05: $admin_answer_05
                        answer_06: $admin_answer_06
                        answer_07: $admin_answer_07
                        answer_08: $admin_answer_08
                        answer_09: $admin_answer_09
                        answer_18: $admin_answer_10
                    }
                }
            }
        ) {
            patient_id
            member_id
            drug_patient
            drug_admin_patient
            enrollment_status
            benefits_amounts {
                current_period {
                    total_amount_drug
                    total_amount_drug_admin
                    balance_amount_drug
                    balance_amount_drug_admin
                    used_amount_drug
                    used_amount_drug_admin
                    use_count_drug
                    use_count_drug_admin
                }
            }
            enrollment_data {
                first_name
                last_name
                date_of_birth
                gender
                phone
            }
            verification_data
            credentials_id
            patient_data
        }
    }
`;
